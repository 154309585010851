import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import clsx from "clsx";
import {
  defaultVoicemailValues,
  GENERIC_SUCCESS_MESSAGE,
  GREETING_AUDIO_ID,
  INPUT_ATTACH_AUDIO_LABEL,
  INPUT_DESCRIPTION_LABEL,
  INPUT_DESCRIPTION_PLACEHOLDER,
  INPUT_DESCRIPTION_REQUIRED_ERROR,
  INPUT_DISPLAY_TRANSCRIPTS_LABEL,
  INPUT_EMAIL_ERROR,
  INPUT_EMAIL_LABEL,
  INPUT_EMAIL_PLACEHOLDER,
  INPUT_GREETING_PLACEHOLDER,
  INPUT_SHOW_TRANSCRIPTS_LABEL,
  PAGE_FOR_MORE_DETAILS,
  PLANS_AND_PRICING,
  THIS_ACTION_IS_NOT_SUPPORTED,
  vboxFormInitialValues,
  vboxInitialValues,
} from "./constants";
import { IVoicemailBox, IVoicemailBoxForm } from "@models/Voicemailbox.models";
import InputField from "../PresentationComponents/FormComponents/InputField";
import DropdownComponent from "../PresentationComponents/DropdownComponent";
import uiString, { ApiErrorMessages } from "../../constants/uiString";
import useForm from "@helpers/hooks/useForm.hook";
import {
  maxLength,
  requiredField,
  validateEmail,
} from "@helpers/formHelpers/formValidatorHelpers";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PublishIcon from "@material-ui/icons/Publish";
import PlayAudioBasicDialog from "@components/PresentationComponents/AudioComponents/PlayAudioBasicDialog";
import { AudioFileRecordingDialog } from "@components/PresentationComponents/AudioComponents/AudioFileRecordingDialog";
import { IAudioFileDropdown, IFormField } from "@models/form.models";
import { IAudioFile } from "@models/AudioFiles.models";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import { CustomCheckbox } from "@components/PresentationComponents/FormComponents/CustomCheckbox";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import { objectTransformator } from "@helpers/objectFunctions";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { useToasts } from "react-toast-notifications";
import { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { refactorOptions } from "@helpers/formHelpers/refactorOptionsArray";
import { noneAudioFile } from "@components/HoursOfOperation/constants";

interface IVoicemailBoxFormProps {
  vbox: IVoicemailBox | null;
  deleteHandler?: () => Promise<any> | null;
  submitHandler: (form?: any) => Promise<any>;
  audioList: IAudioFile[];
  open: boolean;
  closeHandler: () => any;
  submitText: string;
  headerText: string;
  addFileToAudioList: (file: IAudioFile) => void;
}

const VoicemailBoxForm = ({
  open,
  closeHandler,
  submitHandler,
  deleteHandler,
  vbox,
  audioList,
  submitText,
  headerText,
  addFileToAudioList,
}: IVoicemailBoxFormProps) => {
  const email = useAppSelector((state) => state.authentication.userName);

  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paywallError, setPaywallError] = useState(false);
  const [openAudioDialog, setOpenAudioDialog] = useState(false);
  const [openRecordingDialog, setOpenRecordingDialog] = useState(false);
  const [selectedAudioFile, setSelectedAudioFile] = useState<IAudioFile | null>(
    null
  );
  const [currentAudioId, setCurrentAudioId] = useState("");
  const { addToast } = useToasts();
  const classes = useStyles();
  const [error, setError] = useState<{
    email: string[];
    description: string[];
  } | null>(null);
  const initialValues = {
    id: { initialValue: vbox ? vbox.id : "", validators: [] },
    addTranscriptToEmail: {
      initialValue: vbox ? vbox.addTranscriptToEmail : true,
      validators: [],
    },
    displayTranscripts: {
      initialValue: vbox ? vbox.displayTranscripts : true,
      validators: [],
    },
    addAudioToEmail: {
      initialValue: vbox ? vbox.addAudioToEmail : true,
      validators: [],
    },
    greetingAudioId: {
      initialValue: vbox
        ? vbox.greetingAudio?.id ?? noneAudioFile.id
        : noneAudioFile.id,
      validators: [requiredField()],
    },
    description: {
      initialValue: vbox ? vbox.description : "",
      validators: [
        maxLength(255, "The description may not be longer than 255 characters"),
        requiredField(INPUT_DESCRIPTION_REQUIRED_ERROR),
      ],
    },
    email: {
      initialValue: vbox ? (vbox.email ? vbox.email : "") : email ? email : "",
      validators: [validateEmail(INPUT_EMAIL_ERROR)],
    },
  };

  const audioDropdownOptions = refactorOptions(audioList).sort(
    (a, b) => a.type?.localeCompare(b.type!) || a.text.localeCompare(b.text)
  );
  const {
    form,
    formErrors,
    changeHandler,
    validateForm,
    resetToDefault,
    setFormErrors,
    validateField,
  } = useForm<IVoicemailBoxForm>(initialValues);

  useEffect(() => {
    if (!selectedAudioFile && audioList.length) {
      setSelectedAudioFile(audioList[0]);
    }
  }, [audioList]);

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
      setSuccess(false);
      setPaywallError(false);
    };
  }, [open, form]);

  useEffect(() => {
    if (currentAudioId !== '') audioChangeHandler( 'greetingAudioId', currentAudioId );
  }, [currentAudioId]);

  useEffect(() => {
    if (open) {
      resetToDefault();
    }
  }, [vbox, open]);

  useEffect(() => {
    open && setFormErrors({});
  }, [open]);

  const recordSubmitHandler = (file: IAudioFile) => {
    addFileToAudioList(file);
    setCurrentAudioId(file.id);
    changeHandler('greetingAudioId', file.id);
  };

  const handleSubmitClick = async () => {
    try {
      if (!isSubmitting && !success) {
        setError(null);
        setIsSubmitting(true);
        const isValid = validateForm();
        if (open && isValid) {
          let _form = {};
          for (let field in form) {
            if (field === "email" && !Boolean(form.email)) {
              continue;
            } else {
              _form[field] = form[field];
            }
          }
          await submitHandler(_form);
          setSuccess(true);
          closeHandler();
        }
      }
      setIsSubmitting(false);
    } catch (err) {
      if (open) {
        setIsSubmitting(false);
        // @ts-ignore
        if (err.response?.status === 402) {
          setPaywallError(true);
        }
        if (
          (err as any).response &&
          (err as any).response.status === ErrorStatusCode.FORBIDDEN
        ) {
          addToast(ApiErrorMessages.FORBIDDEN, {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (
          (err as any).response &&
          (err as any).response.status === ErrorStatusCode.BAD_ENTITY
        ) {
          setError((err as any).response.data.errors);
        } else {
          addToast(ApiErrorMessages.UNEXPECTED, {
            appearance: "error",
            autoDismiss: true,
          });
          throw err;
        }
      }
    }
  };

  const audioChangeHandler = (
    name: string | undefined,
    value: unknown,
    validateOnChange?: boolean
  ) => {
    const findFile =
      audioList.find((file) => file.id === (value as string)) ?? null;
    setSelectedAudioFile(findFile);
    changeHandler(name, value);
    validateOnChange && validateField(name!, value as string);
  };

  return (
	<Dialog
	  title={headerText}
	  maxWidth="sm"
	  fullWidth
	  open={open}
	  onClose={closeHandler}
	  aria-labelledby="voicemail-settings-dialog-title"
	>
	  <form>
		<DialogContent>
		  <InputField
			placeholder={INPUT_DESCRIPTION_PLACEHOLDER}
			value={form.description}
			onChange={(e) => changeHandler(e.target.name, e.target.value)}
			label={INPUT_DESCRIPTION_LABEL}
			name="description"
			type="text"
			wrapperClass={classes.inputMargin}
			errorMessage={
			  formErrors.description?.validatorResults.errorMessage ||
			  (error?.description && error.description[0])
			}
			error={
			  formErrors.description?.hasError || Boolean(error?.description)
			}
		  />
		  <div className={classes.greetingAudioContainer}>
			<FormControl className={classes.greetingAudio}>
			  <DropdownComponent
				label={INPUT_GREETING_PLACEHOLDER}
				onChange={(e) =>
				  audioChangeHandler(e.target.name, e.target.value)
				}
				value={form.greetingAudioId}
				name={GREETING_AUDIO_ID}
				outlined
				smallIcon
				errorMessage={
				  formErrors.greetingAudioId?.validatorResults.errorMessage
				}
				error={formErrors.greetingAudioId?.hasError}
				dropdownStyle={{ minWidth: "193px" }}
				className="text-light"
				options={audioDropdownOptions}
			  />
			</FormControl>
			<div className={classes.audioButtons}>
			  {form.greetingAudioId && form.greetingAudioId !== noneAudioFile.id && (
				<Tooltip
				  title="Play"
				  styling="standard"
				  disabled={!form.greetingAudioId}
				>
				  <IconButton
					onClick={() => setOpenAudioDialog(true)}
					disabled={!form.greetingAudioId}
					color="primary"
					className={classes.alignButtons}
				  >
					<PlayCircleFilledIcon />
				  </IconButton>
				</Tooltip>
			  )}
			  <Tooltip styling="standard" title="Upload">
				<IconButton
				  onClick={() => setOpenRecordingDialog(true)}
				  color="primary"
				  className={classes.alignButtons}
				>
				  <PublishIcon />
				</IconButton>
			  </Tooltip>
			</div>
		  </div>
		  <FormGroup className={classes.formGroup}>
			<FormControlLabel
			  control={
				<CustomCheckbox
				  onChange={(e) =>
					changeHandler(e.target.name, e.target.checked)
				  }
				  value={form.displayTranscripts}
				  checked={form.displayTranscripts}
				  name="displayTranscripts"
				/>
			  }
			  label={INPUT_DISPLAY_TRANSCRIPTS_LABEL}
			/>
			<FormControlLabel
			  control={
				<CustomCheckbox
				  value={form.addAudioToEmail}
				  checked={form.addAudioToEmail}
				  onChange={(e) =>
					changeHandler(e.target.name, e.target.checked)
				  }
				  name="addAudioToEmail"
				/>
			  }
			  label={INPUT_ATTACH_AUDIO_LABEL}
			/>
			<FormControlLabel
			  control={
				<CustomCheckbox
				  value={form.addTranscriptToEmail}
				  checked={form.displayTranscripts && form.addTranscriptToEmail}
				  name="addTranscriptToEmail"
				  disabled={!form.displayTranscripts}
				  onChange={(e) =>
					changeHandler(e.target.name, e.target.checked)
				  }
				/>
			  }
			  label={INPUT_SHOW_TRANSCRIPTS_LABEL}
			/>
		  </FormGroup>
		  <div className={clsx(classes.inputMarginLast, classes.emailInput)}>
			<InputField
			  placeholder={INPUT_EMAIL_PLACEHOLDER}
			  label={INPUT_EMAIL_LABEL}
			  onChange={(e) => {
				changeHandler(e.target.name, e.target.value);
			  }}
			  value={form.email}
			  name="email"
			  type="text"
			  errorMessage={
				formErrors.email?.validatorResults.errorMessage ||
				(error?.email && error?.email[0])
			  }
			  error={formErrors.email?.hasError || Boolean(error?.email)}
			/>
		  </div>
		  {paywallError && (
			<Alert style={{ marginTop: 15 }} severity="error">
			  <AlertTitle>{uiString.ERROR}</AlertTitle>
			  {THIS_ACTION_IS_NOT_SUPPORTED}
			  <a href="https://talkroute.com/pricing/">{PLANS_AND_PRICING}</a>
			  {PAGE_FOR_MORE_DETAILS}
			</Alert>
		  )}
		  {success && (
			<Alert style={{ marginTop: 15 }} severity="success">
			  {GENERIC_SUCCESS_MESSAGE}{" "}
			</Alert>
		  )}
		  {selectedAudioFile && (
			<PlayAudioBasicDialog
			  open={openAudioDialog}
			  close={() => setOpenAudioDialog(false)}
			  audioSrc={selectedAudioFile.link}
			  autoplay
			/>
		  )}
		  <AudioFileRecordingDialog
			open={openRecordingDialog}
			close={() => setOpenRecordingDialog(false)}
			onSubmit={recordSubmitHandler}
			descriptionDefaultValues={defaultVoicemailValues}
		  />
		</DialogContent>
	  </form>
	  <DialogActions>
		<DialogButton
		  disabled={isSubmitting}
		  isLoading={isSubmitting}
		  color="secondary"
		  fullWidth
		  onClick={handleSubmitClick}
		  label={submitText}
		/>
	  </DialogActions>
	</Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
	inputMargin: {
	  marginBottom: theme.spacing(3),
	},
	inputMarginLast: {
	  marginTop: theme.spacing(3),
	},
	emailInput: {
	  maxWidth: 250,
	  [theme.breakpoints.down('sm')]: {
		maxWidth: '100%',
	  },
	},
	actions: {
	  padding: theme.spacing(2, 3, 0, 3),
	},
	greetingAudioContainer: {
	  display: 'flex',
	  flexDirection: 'column',
	  marginBottom: theme.spacing(3),
	},
	greetingAudio: {
	  display: "flex",
	  flexDirection: "row",
	  alignItems: "center",
	  [theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	  },
	},
	audioButtons: {
	  display: 'flex',
	  marginTop: theme.spacing(1),
	},
	alignButtons: {
	  marginRight: theme.spacing(1),
	},
	formGroup: {
	  marginBottom: theme.spacing(2),
	},
  }));

export default React.memo(VoicemailBoxForm);
