import uiString from "@constants/uiString";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import InputButton from "./FormComponents/InputButton";
import Dialog from "../PresentationComponents/Dialog/Dialog";
import { ComponentDimensions } from "@constants/appConstants";
import clsx from "clsx";

const PaywallStrings = uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG;

type IProps = {
  open: boolean;
  onClose: () => void;
  text: JSX.Element;
  title: string;
  dismissable?: boolean;
  fullSizePreferences: boolean;
};

export const PaywallDialog = ({ onClose, open, text, title, dismissable = true, fullSizePreferences }: IProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ fullSizePreferences, isMobile });

  if (isMobile && !fullSizePreferences) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="paywall-dialog-title"
      aria-describedby="paywall-dialog-description"
      title={title}
      dismissable={dismissable}
      className={clsx(classes.root, {
        [classes.mobileRoot]: isMobile,
      })}
      disableBackdropClick={!dismissable}
    >
      <DialogContent className={classes.content}>
        <DialogContentText data-testid="dialog-paywall-content" id="paywall-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <InputButton
          onClick={() => window.open(PaywallStrings.PRICING_PAGE, "_blank")}
          color="primary"
          autoFocus
          fullWidth={isMobile}
          label={PaywallStrings.REDIRECT_LABEL}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, { fullSizePreferences: boolean, isMobile: boolean }>((theme) => createStyles({
	root: {
	  top: `${ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT}px !important`,
	  left: ({ fullSizePreferences, isMobile }) => 
		`${isMobile ? 0 : (!fullSizePreferences ? ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH : 0)}px !important`,
	  "& .MuiDialog-container": {
		alignItems: "center",
		justifyContent: "center",
	  },
	  "& .MuiDialog-paper": {
		margin: 0,
		width: "calc(100% - 32px)",
		maxWidth: '500px',
		maxHeight: `calc(100% - ${ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT + 32}px)`,
		position: 'absolute',
		transition: theme.transitions.create(['opacity', 'transform'], {
		  duration: theme.transitions.duration.standard,
		}),
	  },
	  "& .MuiBackdrop-root": {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		top: ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT,
		left: ({ fullSizePreferences, isMobile }) => 
			`${isMobile ? 0 : (!fullSizePreferences ? ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH : 0)}px !important`,
		right: 0,
		bottom: 0,
		width: 'auto',
		height: 'auto',
		transition: theme.transitions.create('left', {
		  duration: theme.transitions.duration.standard,
		}),
	  },
	},
	mobileRoot: {
	  "& .MuiDialog-paper": {
		opacity: ({ fullSizePreferences }) => `${!fullSizePreferences ? 0 : 1} !important`,
		transform: ({ fullSizePreferences }) => 
			!fullSizePreferences ? 'translateX(-100%)' : 'translateX(0)',
		pointerEvents: ({ fullSizePreferences }) => !fullSizePreferences ? 'none' : 'auto',
	  },
	  "& .MuiBackdrop-root": {
		opacity: ({ fullSizePreferences }) => `${!fullSizePreferences ? 0 : 1} !important`,
		pointerEvents: ({ fullSizePreferences }) => !fullSizePreferences ? 'none' : 'auto',
	  },
	},
	content: {
	  overflow: 'auto',
	},
	actions: {
	  padding: theme.spacing(2),
	  [theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		alignItems: 'stretch',
	  },
	},
}));