// import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import { ComponentDimensions } from "@constants/appConstants";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import {
  Divider,
  Link,
  List,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import appConfig from "../../config";
import {
  phoneSystemItems,
  companyItems,
  NavigationItems,
  yourProfileItems,
} from "../../constants/navItems";
import uiString from "../../constants/uiString";
import PreferenceNavItem from "./PreferenceNavItem";
import ExitRightIcon from "@resources/icons/exit-right.svg";
import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import { isMobileDevice } from "@utils";

type Props = {
  handleItemClick: (name: string) => void;
  activeItem: string;
  fullSizePreferences: boolean;
};

/**
 * Draw the navigation items
 */
const PreferencesNavigation: React.FC<Props> = ({
  handleItemClick,
  activeItem,
  fullSizePreferences,
}) => {
  const classes = useStyles({ fullSizePreferences });
  const userRole = useAppSelector((state) => state.authentication.userRole);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isUsingMobileDevice = isMobileDevice();
  
  /**
   * Draws each list item
   * @param items object with list of navigation pages
   */
  const showNavItems = (items: NavigationItems) => {
    const userIsAdmin = userRole === "Account Admin";
    const itemsToShow = Object.values(items);

	const filteredItems = isUsingMobileDevice
      ? itemsToShow.filter(item => 
          !['Caller ID', 'Controls', 'Notifications & Sounds'].includes(item.value)
        )
      : itemsToShow;

    return filteredItems.map(({ value, adminOnly, link, domId, Component, desktopOnly }, index) => {
      if (appConfig.clientType === "web" && desktopOnly) {
        return null;
      }
      const key = createKeyIndex(index, `${value}-${link}`);
      const itemIsAvailable = userIsAdmin || !adminOnly;

      if (!itemIsAvailable) return '';
      let Content = (
        <PreferenceNavItem
          disabled={!itemIsAvailable}
          key={key}
          id={domId}
          onClick={(name) => itemIsAvailable && handleItemClick(name)}
          activeItem={activeItem}
          value={value}
        />
      );

      if (Component === null)
        Content = (
          <Link
            key={key}
            id={domId}
            className={clsx(classes.link, !itemIsAvailable && classes.disabled)}
            style={{cursor: "pointer"}}
            onClick={() => itemIsAvailable && window.open(link, '_blank')}
          >
            {value}
            <ExitRightIcon style={{ marginLeft: "12px", pointerEvents: "none"}} />
          </Link>
        );

      return itemIsAvailable ? (
        Content
      ) : (
        <Tooltip key={key} styling="standard" placement="bottom" title="Available Only To Admins">
          <div>{Content}</div>
        </Tooltip>
      );
    }).filter(item => item !== null);
  };

  if (isMobile && fullSizePreferences) {
    return null; // Don't render navigation when it's hidden on mobile
  }
  
  return (
    <div className={classes.navContainer}>
      <List className={classes.navList} component="nav">
        <Typography variant="h6" className={classes.navSectionHeader}>
          {uiString.preferences.YOUR_PROFILE}
        </Typography>
        <Divider className={classes.divider} />
        {showNavItems(yourProfileItems)}
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.navSectionHeader}>
          {uiString.preferences.PHONE_SYSTEM}
        </Typography>
        <Divider className={classes.divider} />
        {showNavItems(phoneSystemItems)}
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.navSectionHeader}>
          {uiString.preferences.COMPANY}
        </Typography>
        <Divider className={classes.divider} />
        {showNavItems(companyItems)}
      </List>
    </div>
  );
};

const useStyles = makeStyles<Theme, Pick<Props, 'fullSizePreferences'>>((theme) => ({
	navContainer: {
		position: 'fixed',
		top: ComponentDimensions.SETTINGS_AND_PREFERENCES_HEADER_HEIGHT,
		left: 0,
		bottom: 0,
		width: ComponentDimensions.PREFERENCES_NAVIGATION_WIDTH,
		backgroundColor: theme.palette.common.whiteTan,
		overflowY: 'auto',
		transition: 'transform 0.3s ease-in-out',
		zIndex: 999999,
		transform: ({ fullSizePreferences }) => fullSizePreferences ? 'translateX(-100%)' : 'translateX(0)',
		[theme.breakpoints.down('sm')]: {
		  width: '100%',
		},
	},
	navList: {
	  paddingTop: theme.spacing(2),
	},
	navSectionHeader: {
	  fontSize: ComponentDimensions.PREFERENCES_PAGE_HEADER_FONT_SIZE,
	  paddingBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
	  color: theme.palette.common.textBlack,
	  lineHeight: "21px",
	  padding: theme.spacing(0, 2),
	},
	link: {
	  display: "flex",
	  alignItems: "center",
	  fontSize: ComponentDimensions.PREFERENCES_NAVIGATION_LINK_FONT_SIZE,
	  lineHeight: "normal",
	  padding: theme.spacing(1, 2),
	  width: "100%",
	  color: theme.palette.common.charcoal,
	  "&:hover": {
		color: theme.palette.common.charcoal,
		backgroundColor: "white",
		textDecoration: "none",
	  },
	},
	disabled: {
	  opacity: 0.5,
	  "&:hover": {
		background: "none"
	  }
	},
	divider: {
	  marginBottom: ComponentDimensions.PREFERENCES_PAGE_HEADER_DIVIDER_MARGIN,
	  backgroundColor: "rgba(0, 0, 0, 0.12)",
	},
  }));

export default PreferencesNavigation;
