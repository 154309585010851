import React from "react";
import { phoneSystemItems, companyItems, yourProfileItems } from "../../constants/navItems";
import CallerID from "./CallerID";

type Props = {
  activeItem: string;
  fullSizePreferences: boolean;
};

/**
 * Draw the contents of preferences
 */
const ShowContent: React.FC<Props> = ({ activeItem, fullSizePreferences }) => {
  const renderComponent = (Component: React.ComponentType<any>) => {
	  return <Component fullSizePreferences={fullSizePreferences} />;
  };

  for (const { Component, value } of Object.values(phoneSystemItems)) {
    if (value === activeItem && Component !== null) return renderComponent(Component);
  }
  for (const { Component, value } of Object.values(companyItems)) {
    if (value === activeItem && Component !== null) return renderComponent(Component);
  }
  for (const { Component, value } of Object.values(yourProfileItems)) {
    if (value === activeItem && Component !== null) return renderComponent(Component);
  }
  return <CallerID />;
};

export default React.memo(ShowContent);
