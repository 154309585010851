import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import Loader from "@components/PresentationComponents/Loader";
import PlainTable from "@components/PresentationComponents/TableComponents/PlainTable";
import { UnderlinedTitle } from "@components/PresentationComponents/UnderlinedTitle";
import { ComponentDimensions } from "@constants/appConstants";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import {
  Button,
  createStyles,
  FormControl,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { FeatureIdsEnum } from "@models/Account.models";
import { IPagination } from "@models/IPagination";
import {
  ITransferCode,
  MapTransferCodeTypeToLabel,
  TransferCodeTypes,
} from "@models/TransferCodes.models";
import PrintIcon from "@resources/icons/printer-min.svg";
import TransferCodesService from "@services/TransferCodes.service";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import uiString, { siteLinks } from "../../constants/uiString";
import OutlinedDropdown from "../PresentationComponents/FormComponents/OutlinedDropdown";

const { GetByPage, GetExtensionsByPage } = TransferCodesService();

const placeholderData = [
  ["0001", "Sales Department", TransferCodeTypes.MENU_OPTION],
  ["0002", "Support Department", TransferCodeTypes.MENU_OPTION],
  ["0003", "Billing Department", TransferCodeTypes.MENU_OPTION],
  ["0000", "Operator Department", TransferCodeTypes.MENU_OPTION],
  ["0101", "18005554321", TransferCodeTypes.DID],
  ["0102", "17085551234", TransferCodeTypes.DID],
  ["0201", "Office Phone - 13125554213", TransferCodeTypes.FORWARDING_DEVICE],
  [
    "0202",
    "John’s Cell Phone - 17735552431",
    TransferCodeTypes.FORWARDING_DEVICE,
  ],
  [
    "0203",
    "Jennifer’s Cell Phone - 16305553421",
    TransferCodeTypes.FORWARDING_DEVICE,
  ],
  [
    "0204",
    "Overflow Call Center - 18885551324",
    TransferCodeTypes.FORWARDING_DEVICE,
  ],
  ["0301", "Sales Mailbox", TransferCodeTypes.VOICEMAIL],
  ["0302", "Support Mailbox", TransferCodeTypes.VOICEMAIL],
  ["0303", "Billing Mailbox", TransferCodeTypes.VOICEMAIL],
  [
    "0401",
    "John Smith (jsmith@mycompany.com)",
    TransferCodeTypes.WEBRTC_DEVICE,
  ],
  [
    "0402",
    "Jennifer Parker (jparker@mycompany.com)",
    TransferCodeTypes.WEBRTC_DEVICE,
  ],
];

const trimDescription = (desc: string) => {
  let _desc = desc;
  if (!_desc) return "";
  if (_desc[0] === "-") _desc = _desc.substring(1, _desc.length);
  if (_desc[_desc.length - 1] === "-")
    _desc = _desc.substring(0, _desc.length - 1);
  return _desc;
};
const transferCodesPlaceholder: ITransferCode[] = placeholderData.map(
  (dataPoint, index) => ({
    id: String(dataPoint[0]),
    digits: dataPoint[0],
    enabled: false,
    destination: {
      id: String(index + 1),
      type: dataPoint[2],
      display: dataPoint[1],
      link: "",
    },
  })
);
const transferCodesAllOption = "Type - All";
const WhiteToolTip = withStyles((theme: Theme) =>
  createStyles({
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      fontSize: "14px",
    },
  })
)(Tooltip);

type TransferCodesProps = {
	fullSizePreferences: boolean;
};

const TransferCodes: React.FC<TransferCodesProps> = ({ fullSizePreferences }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [data, setData] = useState<ITransferCode[]>([]);
  const [limitTableHeight, setLimitTableHeight] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<
    TransferCodeTypes | typeof transferCodesAllOption
  >(transferCodesAllOption);
  const rowsPerPage = useAppSelector(
    (state) => state.preferences.tableSize.transferCodes
  );
  const [componentIsLoaded, setComponentIsLoaded] = useState(true);
  const { GuardDialog, permissionGuard, isAllowed } = useAccessBlocker(
    FeatureIdsEnum.CALL_TRANSFER,
    false,
	fullSizePreferences,
  );
  const printTrigger = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: () => setLimitTableHeight(true),
    bodyClass: classes.printBody,
  });
  const handlePrint = () => {
    transferCodesGET(1, true);
    // setLimitTableHeight(false);
    setTimeout(printTrigger as any, 500);
  };
  const transferCodesGET = permissionGuard(
    async (page?: number, takeMax?: boolean) => {
      try {
        setIsLoading(true);
        const type: TransferCodeTypes | undefined =
          filter !== transferCodesAllOption ? filter : undefined;

        const { data, pagination } = await GetByPage(
          page ?? 1,
          // takeMax ? (pageState?.total as number) : rowsPerPage,
          10000,
          type
        );
        const { data: extensionsResponseData } = await GetExtensionsByPage(
          1,
          10000
        );

        const extData: ITransferCode[] = extensionsResponseData.map((ext) => ({
          id: ext.id,
          digits: ext.number,
          enabled: ext.enabled,
          destination: {
            id: ext.destination.id,
            type: "extensions",
            display: ext.name,
            link: "n/a",
          },
        }));

        if (componentIsLoaded) {
          setPageState(pagination);
          setData([...data, ...extData]);
          setIsLoading(false);
          setFirstLoad(false);
        }
      } catch (error) {
        setIsLoading(false);
        setFirstLoad(false);
      }
    }
  );

  useEffect(() => {
    return () => setComponentIsLoaded(false);
  }, []);

  useEffect(() => {
    transferCodesGET();
  }, []);

  const dataToDisplay = useMemo(
    () =>
      filter === transferCodesAllOption
        ? data
        : data.filter((tc) => tc.destination.type === filter),
    [data, filter]
  );

  const handleFilter = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setFilter(
      e.target.value as TransferCodeTypes | typeof transferCodesAllOption
    );
  };

  const handlePageChange = (page: number) => transferCodesGET(page);

  const dropdownOptions = [
    { value: transferCodesAllOption, text: transferCodesAllOption },
    {
      value: TransferCodeTypes.DID,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.DID],
    },
    {
      value: TransferCodeTypes.MENU_OPTION,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.MENU_OPTION],
    },
    {
      value: TransferCodeTypes.FORWARDING_DEVICE,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.FORWARDING_DEVICE],
    },
    {
      value: TransferCodeTypes.VOICEMAIL,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.VOICEMAIL],
    },
    {
      value: TransferCodeTypes.WEBRTC_DEVICE,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.WEBRTC_DEVICE],
    },
    {
      value: TransferCodeTypes.EXTENSIONS,
      text: MapTransferCodeTypeToLabel[TransferCodeTypes.EXTENSIONS],
    },
  ];

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>
        <>
          {uiString.preferences.TRANSFER_CODES}
          <WhiteToolTip
            interactive
            title={
              <>
                <Typography>
                  {uiString.transferCodes.TOOLTIP.FIRST_LINE}
                </Typography>
                <br />
                <Typography>
                  {uiString.transferCodes.TOOLTIP.SECOND_LINE}
                </Typography>
                <br />
                <Typography>
                  {uiString.transferCodes.TOOLTIP.THIRD_LINE}{" "}
                  <Link href={siteLinks.TRANSFER_CODES_ARTICLE}>
                    click here
                  </Link>
                </Typography>
              </>
            }
            placement={isMobile ? "bottom" : "right"}
            arrow
          >
            <Help
              fontSize="default"
              color="action"
              style={{
                verticalAlign: "middle",
                marginLeft: "7px",
              }}
            />
          </WhiteToolTip>
        </>
      </UnderlinedTitle>
      {(!firstLoad || !isAllowed) && (
        <Grid className={classes.content}>
          <div className={classes.controls}>
            <FormControl className={classes.filterControl}>
              <OutlinedDropdown
                labelId="transfer-codes-filter-select"
                onChange={handleFilter}
                value={filter}
                options={dropdownOptions}
                fullWidth
              />
            </FormControl>
            <Tooltip
              styling="standard"
              placement="top"
              title={uiString.tooltips.PRINT_BUTTON}
            >
              <Button className={classes.printButton} onClick={handlePrint}>
                <PrintIcon style={{ transform: "scale(0.7)" }} />
              </Button>
            </Tooltip>
          </div>
          <div ref={ref}>
            <PlainTable
              data={isAllowed ? dataToDisplay : transferCodesPlaceholder}
              selectColumns={["code", "description"]}
              columnSize={isMobile ? undefined : ["25%", "41%", "auto"]}
              groupBy={"type"}
              dataMapper={(row) => ({
                ...row,
                code: row.digits,
                description: trimDescription(row.destination.display),
                type: MapTransferCodeTypeToLabel[row.destination.type],
              })}
              fullWidth
            />
          </div>
        </Grid>
      )}
      <GuardDialog />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
		width: "100%",
		padding: 0,
	},
	controls: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: theme.spacing(2),
		alignItems: "center",
		flexDirection: "row",
		[theme.breakpoints.down('sm')]: {
		  flexDirection: "column",
		  alignItems: "stretch",
		},
	},
	filterControl: {
		[theme.breakpoints.down('sm')]: {
		  marginBottom: theme.spacing(2),
		  width: "100%",
		},
	},
	printButton: {
		height: "34px",
		[theme.breakpoints.down('sm')]: {
		  width: "100%",
		},
	},
    printBody: {
      "& td": {
        paddingLeft: "8px !important",
        fontSize: `${
          ComponentDimensions.TABLE_HEADER_FONT_SIZE * 0.9
        }px !important`,
        height: ComponentDimensions.TABLE_BODY_ROW_HEIGHT * 0.9,
      },
      "& th": {
        paddingLeft: "8px !important",
        fontSize: `${
          ComponentDimensions.TABLE_HEADER_FONT_SIZE * 0.9
        }px !important`,
        height: ComponentDimensions.TABLE_BODY_ROW_HEIGHT * 0.9,
      },
    },
  })
);

export default TransferCodes;
