import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";
import InputField from "@components/PresentationComponents/FormComponents/InputField";
import { 
  addPhoneNumberPlusSign,
  formatBothNationalInternational,
  formatNumberAsYouType,
  formatNumberToE164,
  trimPhoneNumber, 
} from "@helpers/functions/phoneNumberFormatter";
import useForm from "@helpers/hooks/useForm.hook";
import { DialogProps, IconButton } from "@material-ui/core";
import { IContactFormData, IContact, IContactBody, IContactPhoneNumber } from "@models/Contacts.models";
import React, { FC, memo, useEffect, useState } from "react";
import PlusIcon from "@resources/icons/plus-min.svg";
import { makeStyles } from "@material-ui/styles";
import { 
  ADD_EMAIL,
  ADD_NUMBER,
  COMPANY, 
  contactFormInitialValues, 
  contactInitialValues, 
  CONTACTS_COMPANY_PLACEHOLDER, 
  CONTACTS_EMAIL_PLACEHOLDER, 
  CONTACTS_NAME_PLACEHOLDER, 
  CONTACTS_PHONE_NUMBER_PLACEHOLDER, 
  CONTACTS_ROLE_PLACEHOLDER, 
  CONTACTS_URL_PLACEHOLDER, 
  CONTACT_NAME, 
  CREATE_A_NEW_CONTACT, 
  EMAIL_ADDRESS, 
  phoneTypeOptions, 
  PHONE_NUMBER, 
  ROLE, 
  SAVE_CONTACT, 
  UPDATE_CONTACT, 
  UPDATE_EXISTING_CONTACT, 
  URL 
} from "./constants";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CustomLabel from "@components/PresentationComponents/FormComponents/CustomLabel";
import clsx from "clsx";
import { objectTransformator } from "@helpers/objectFunctions";
import { 
  addHttpsToUrl, 
  removeHttpsFromUrl, 
  trimEmails
} from "@helpers/functions/contacts";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import ContactsPhoneTypeRow from "./ContactsPhoneTypeRow";
import keyConstants from "@constants/keyConstants";
import { openDialog } from '@actions/appActions';
import { useDispatch } from "react-redux";
import { refreshPhonenumbers } from "@reducers/contactsReducer";
import { 
  useAddContactPhoneNumberMutation, 
  useCreateContactMutation, 
  useDeleteContactPhoneNumberMutation, 
  useUpdateContactMutation, 
  useUpdateContactPhoneNumberMutation 
} from "@services/kingcobraApi";

type IProps = {
  open: boolean;
  onClose: () => void;
  contactData: IContact | null;
} & Omit<DialogProps, "">

const CreateContactDialog: FC<IProps> = (
  { onClose, contactData, open, ...props }
) => {
  const { 
    form, changeHandler, resetToDefault, formErrors, validateForm, setForm, setFormErrors
  } = useForm<IContactFormData>(contactFormInitialValues);

  const [CreateContact, {isLoading: isContactCreationPending}] = useCreateContactMutation();
  const [UpdateContact, {isLoading: isContactUpdatePending}] = useUpdateContactMutation();
  const [AddPhoneNumber, {isLoading: isPhoneNumberAttachmentPending}] = useAddContactPhoneNumberMutation();
  const [UpdatePhoneNumber, {isLoading: isPhoneNumberUpdatePending}] = useUpdateContactPhoneNumberMutation();
  const [DeletePhoneNumber, {isLoading: isPhoneNumberDeletionPending}] = useDeleteContactPhoneNumberMutation();
  const [requestPending, setRequestPending] = useState(false)
  const classes = useStyles();
  const [showAddPhoneButton, setShowAddPhoneButton] = useState(false);
  const [showAddEmailButton, setShowAddEmailButton] = useState(false);
  const dispatch = useDispatch();
  const CONTACTS_FIRST_INDEX = 0;

  const createContact = async () => {
    const transformedUrl = addHttpsToUrl(form.url);

    const body: Partial<Omit<IContactBody, "emails"> & {emails: string[] | null}> = {
      name: form.name
    };
    if (form.company != "") body.company = form.company;
    if (form.emails.length > 0) body.emails = trimEmails(form.emails);
    if (form.notes != "") body.notes = form.notes;
    if (form.role != "") body.role = form.role;
    if (form.url != "") body.url = transformedUrl;
    !body.emails && delete body.emails;
    if (validateForm()) {
      setRequestPending(true)
      try {
        const {data} = await CreateContact(body as IContactBody).unwrap();
        let newContact = { ...data, phoneNumbers: [] as IContactPhoneNumber[] };
        for (let i = 0; i < form.phoneNumbers.length; i++) {
          if (form.phoneNumbers[i].phoneNumber !="") {
            const response = await AddPhoneNumber({contactId: data.id, numberToAdd: {
              phoneNumber: formatNumberToE164(form.phoneNumbers[i].phoneNumber),
              type: form.phoneNumbers[i].type
          }}).unwrap(); 
            newContact.phoneNumbers.push(response.data);
          }
        };
 
        /**
         * Add the new contact to the store.
         * Note that this will trigger call history, voicemails and text conversations to update
         * and show the new contacts name inplace of phone numbers
         */
        dispatch(refreshPhonenumbers());
        setRequestPending(false);
        resetToDefault();
        onClose();
      } catch (error) {
        setRequestPending(false);
        throw error
      };
    };
  };

  const editContact = async () => {
    const transformedUrl = addHttpsToUrl(form.url);

    const body: Partial<Omit<IContact, "id" | "emails">> & {id: string, emails: string[] | null} = {
      id: form.id,
      name: form.name,
      company: form.company,
      emails: trimEmails(form.emails),
      notes: form.notes,
      role: form.role,
      url: transformedUrl,
      phoneNumbers: form.phoneNumbers ?? undefined
    };

    try {
      if (validateForm()) {
        setRequestPending(true)
        await UpdateContact(body).unwrap();
        for (let i = 0; i < form.phoneNumbers.length; i++) {
          const SHOULD_DELETE_EXISTING_PHONE_NUMBER = 
          form.phoneNumbers[i]?.phoneNumber === "" && contactData?.phoneNumbers[i]?.phoneNumber;

          const SHOULD_EDIT_EXISTING_PHONE_NUMBER = contactData?.phoneNumbers[i]?.phoneNumber &&
          (contactData?.phoneNumbers[i]?.phoneNumber !== trimPhoneNumber(form.phoneNumbers[i]?.phoneNumber)
          || contactData?.phoneNumbers[i]?.type !== form.phoneNumbers[i].type);
          
          const SHOULD_ADD_PHONE_TO_CONTACT = !contactData?.phoneNumbers[i]?.phoneNumber && form.phoneNumbers[i]?.phoneNumber;

          if (SHOULD_DELETE_EXISTING_PHONE_NUMBER) {
            await DeletePhoneNumber({contactId: contactData?.id as string, numberId: contactData?.phoneNumbers[i].id as string});
          } 
          else if (SHOULD_EDIT_EXISTING_PHONE_NUMBER) {
            await UpdatePhoneNumber({
              contactId: form.id,
              updatedNumber: {
                id: contactData?.phoneNumbers[i].id as string,
                phoneNumber: formatNumberToE164(form.phoneNumbers[i].phoneNumber),
              type: form.phoneNumbers[i].type

              }
            }).unwrap();
          } 
          else if (SHOULD_ADD_PHONE_TO_CONTACT) {
            await AddPhoneNumber({
              numberToAdd: {
                phoneNumber: formatNumberToE164(form.phoneNumbers[i].phoneNumber),
                type: form.phoneNumbers[i].type
              },
              contactId: form.id
            })
          }
          else {
            continue;
          };
        };
        setRequestPending(false)
        onClose();
      } 
    } catch (error) {
      setRequestPending(false)
      onClose();
      throw error
    };
  };

  // const handleNumberEdgecaseFormat = (phoneNumber: string) => {
  //   if (phoneNumber.length === 4 && phoneNumber.startsWith('(')) return phoneNumber.substring(1,3);
  //   return phoneNumber;
  // }

  const phoneNumberOnKeyDown = (    
    changeEvent: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
) => {
  if (changeEvent.key === "Backspace" || changeEvent.key === "Delete") {
    changeEvent.preventDefault();
    const phoneNumber = form.phoneNumbers[index].phoneNumber;
    let PHONE_NUMBERS_NEW_ARRAY = [ ...form.phoneNumbers ];
    PHONE_NUMBERS_NEW_ARRAY[index] = { 
      phoneNumber: phoneNumber.substring(0, phoneNumber.length - 1), 
      type: form.phoneNumbers[index].type,
      id: form.phoneNumbers[index].id
    };
    changeHandler(
      "phoneNumbers",
      PHONE_NUMBERS_NEW_ARRAY  
    );
  }
  }
  const phoneNumberChangeHandler = (
    changeEvent: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,

    ) => {
    const phoneNumber = changeEvent.currentTarget.value;
    let PHONE_NUMBERS_NEW_ARRAY = [ ...form.phoneNumbers ];
    PHONE_NUMBERS_NEW_ARRAY[index] = { 
      phoneNumber: formatNumberAsYouType(phoneNumber), 
      type: form.phoneNumbers[index].type,
      id: form.phoneNumbers[index].id
    };
    changeHandler(
      "phoneNumbers",
      PHONE_NUMBERS_NEW_ARRAY  
    );
  };

  const phoneTypeChangeHandler = (type, index: number) => {
    let PHONE_NUMBERS_NEW_ARRAY = [ ...form.phoneNumbers ];
    PHONE_NUMBERS_NEW_ARRAY[index] = { 
      phoneNumber: form.phoneNumbers[index].phoneNumber, 
      type,
      id: form.phoneNumbers[index].id
    };
    changeHandler(
      "phoneNumbers", 
      PHONE_NUMBERS_NEW_ARRAY
    );
  };

  /**
   * Will add a new phone number field to form dialog.
   */
  const addPhoneNumberField = () => {
    changeHandler('phoneNumberFieldsCount', form.phoneNumberFieldsCount + 1);
    let PHONE_NUMBERS_NEW_ARRAY = [ ...form.phoneNumbers ];
    PHONE_NUMBERS_NEW_ARRAY.push({
      phoneNumber: "", 
      type: phoneTypeOptions[CONTACTS_FIRST_INDEX].value,
      id: ""
    });
    changeHandler(
      "phoneNumbers",
      PHONE_NUMBERS_NEW_ARRAY  
    );
    setShowAddPhoneButton(false);
  };

  const emailChangeHandler = (email: string, index: number) => {
    let EMAILS_NEW_ARRAY = [ ...form.emails ];
    EMAILS_NEW_ARRAY[index] = email;
    changeHandler("emails", EMAILS_NEW_ARRAY);
  };
  
  /**
   * Will add a new email address field to form dialog.
   */
   const addEmailAddressField = () => {
     changeHandler('emailAddressFieldsCount', form.emailAddressFieldsCount + 1);
     setShowAddEmailButton(false);
  };

  /**
   * Sends request to create new contact, or edit excisting contact depending on initial form data. 
   */
  const submitHandler = contactData?.name ? editContact : createContact;

  const handleClose = () => {
    !contactData && resetToDefault();
    onClose();
  };

  /**
   * Dialog's key down event handler
   */
  const onKeyDown = (e) => {
    if (e.keyCode === keyConstants.CODE_ENTER) {
      e.preventDefault();
      submitHandler();
    };
    if (e.keyCode === keyConstants.CODE_ESCAPE) {
      e.preventDefault();
      onClose();
    };
  };

  /**
   * Display phone number input field and a dropdown field to chose type of phone.
   */
   const renderPhoneNumberField = () => {
    let returnValue: JSX.Element[] = [];
    const name = "phoneNumbers";
    if (form.phoneNumberFieldsCount != 0) {
      for (let i = 0; i < form.phoneNumberFieldsCount; i++) {
        const label = i < 1 ? PHONE_NUMBER : PHONE_NUMBER + ` #${i}`;
        returnValue.push(
          <ContactsPhoneTypeRow
            label={label}
            name={name}
            key={createKeyIndex(i, 'contacts-phone-row')}
            placeholder={CONTACTS_PHONE_NUMBER_PLACEHOLDER}
            value={form.phoneNumbers[i]}
            error={formErrors[name]?.fieldIndex?.includes(i)}
            errorMessage={formErrors[name]?.validatorResults.errorMessage}
            inputChangeHandler={(e) => phoneNumberChangeHandler(e, i)}
            // onKeyDown={(e) => phoneNumberOnKeyDown(e, i)}
            dropdownChangeHandler={(e) => phoneTypeChangeHandler(e.target.value, i)}
          />
        );
      };
    } else {
      returnValue.push(
        <ContactsPhoneTypeRow
          label={PHONE_NUMBER}
          name={name}
          placeholder={CONTACTS_PHONE_NUMBER_PLACEHOLDER}
          key={createKeyIndex(CONTACTS_FIRST_INDEX, 'contacts-type-phone-row')}
          value={form.phoneNumbers[CONTACTS_FIRST_INDEX]}
          error={formErrors[name]?.fieldIndex?.includes(CONTACTS_FIRST_INDEX)}
          errorMessage={formErrors[name]?.validatorResults.errorMessage}
          inputChangeHandler={(e) => phoneNumberChangeHandler(e, CONTACTS_FIRST_INDEX)}
          // onKeyDown={(e) => phoneNumberOnKeyDown(e, CONTACTS_FIRST_INDEX)}
          dropdownChangeHandler={(e) => phoneTypeChangeHandler(e.target.value, CONTACTS_FIRST_INDEX)}
        />
      );
    }
    return returnValue;
  };

  /**
   * Display input field for an email data.
   */
  const renderEmailField = () => {
    let returnValue: JSX.Element[] = [];
    const name = "emails";
    if (form.emailAddressFieldsCount != 0) {
      for (let i = 0; i < form.emailAddressFieldsCount; i++) {
        const label = i < 1 ? EMAIL_ADDRESS : EMAIL_ADDRESS + ` #${i}`;
        returnValue.push(
          <InputField
            label={label}
            placeholder={CONTACTS_EMAIL_PLACEHOLDER}
            wrapperClass={classes.bottomSpacing}
            className={classes.inputField}
            boldLabel
            key={createKeyIndex(i, 'contacts-email-address')}
            error={formErrors[name]?.fieldIndex?.includes(i)}
            errorMessage={formErrors[name]?.validatorResults.errorMessage}
            value={form.emails[i]}
            name={name}
            onChange={(e) => emailChangeHandler(e.target.value as string, i)}
          />
        );
      };
    } else {
      returnValue.push(
        <InputField
          label={EMAIL_ADDRESS}
          placeholder={CONTACTS_EMAIL_PLACEHOLDER}
          wrapperClass={classes.bottomSpacing}
          className={classes.inputField}
          boldLabel
          key={createKeyIndex(CONTACTS_FIRST_INDEX, 'contacts-email-address')}
          error={formErrors[name]?.fieldIndex?.includes(CONTACTS_FIRST_INDEX)}
          errorMessage={formErrors[name]?.validatorResults.errorMessage}
          value={form.emails[CONTACTS_FIRST_INDEX]}
          name={name}
          onChange={(e) => emailChangeHandler(e.target.value as string, CONTACTS_FIRST_INDEX)}
        />
      );
    };
    return returnValue;
  };

  /**
   * Transform the initial contact data to be displayed in the edit form dialog.
   * If contact data isn't provided will set an empty form data to render an empty form dialog. 
   */
  useEffect(() => {
    if (contactData && open) {
      const formData: IContactFormData = { 
        name: contactData.name ? contactData.name : "",
        company: contactData.company ? contactData.company : "",
        role: contactData.role ? contactData.role : "",
        notes: contactData.notes ? contactData.notes : "",
        url: contactData.url ? removeHttpsFromUrl(contactData.url) : "",
        id: contactData.id ?? "",
        emails: Boolean(contactData.emails)
          ? contactData.emails
          : [""],
        emailAddressFieldsCount: !Boolean(contactData.emails) ? 1 : contactData.emails.length,
        phoneNumbers: contactData.phoneNumbers.length > 0 
          ? contactData.phoneNumbers.map(contact => ({
              phoneNumber: formatNumberAsYouType(contact.phoneNumber),
              type: contact.type,
              id: contact.id
          })) 
          : [{ phoneNumber: "", type: phoneTypeOptions[0].value, id: "" }],
        phoneNumberFieldsCount: contactData.phoneNumbers.length === 0 ? 1 : contactData.phoneNumbers.length,
      };
      const transformedContactData = objectTransformator<IContactFormData>(formData, form);
      transformedContactData && setForm(transformedContactData);
    } else {
      setForm(contactInitialValues);
    };
  }, [contactData, open]);

  // On every input change, check if addEmailFieldButton & addPhoneFieldButton should appear or hide.
  useEffect(() => {
    const SHOULD_SHOW_EMAIL_BUTTON = (form.emails.length >= form.emailAddressFieldsCount) && form.emails.every(email => email !== "");
    const SHOULD_HIDE_EMAIL_BUTTON = form.emails.some(email => email === "" || email == undefined);

    const SHOULD_SHOW_PHONE_BUTTON = (form.phoneNumbers.length >= form.phoneNumberFieldsCount) 
      && form.phoneNumbers.every(contact => contact.phoneNumber !== "");
    const SHOULD_HIDE_PHONE_BUTTON = form.phoneNumbers.some(contact => contact.phoneNumber === "" || contact.phoneNumber == undefined);

    if (SHOULD_SHOW_EMAIL_BUTTON) {
      setShowAddEmailButton(true);
    } else if (SHOULD_HIDE_EMAIL_BUTTON) {
      setShowAddEmailButton(false);
    };

    if (SHOULD_SHOW_PHONE_BUTTON) {
      setShowAddPhoneButton(true);
    } else if (SHOULD_HIDE_PHONE_BUTTON) {
      setShowAddPhoneButton(false);
    };
  }, [form])

  // Reset form errors
  useEffect(() => {
    dispatch(openDialog(open));
    !open && setFormErrors({});
  }, [open]);

  return (
	<Dialog
	  {...props}
	  open={open}
	  onClose={() => handleClose()}
	  title={contactData?.name ? UPDATE_EXISTING_CONTACT : CREATE_A_NEW_CONTACT}
	  fullWidth
	  onKeyDown={(e) => onKeyDown(e)}
	>
	  <DialogContent>
		<InputField
		  label={CONTACT_NAME}
		  placeholder={CONTACTS_NAME_PLACEHOLDER}
		  boldLabel
		  autoFocus
		  inputProps={{ maxLength: 100 }}
		  error={formErrors.name?.hasError}
		  errorMessage={formErrors.name?.validatorResults.errorMessage}
		  className={classes.inputField}
		  wrapperClass={classes.bottomSpacing}
		  value={form.name}
		  name="name"
		  onChange={(e) => changeHandler("name", e.target.value)}
		/>
		{renderPhoneNumberField()}
		{showAddPhoneButton &&
		  <IconButton
			onClick={() => addPhoneNumberField()}
			className={clsx(classes.bottomSpacing, classes.iconButton)}
		  >
			{ADD_NUMBER}
			<PlusIcon />
		  </IconButton>
		}
		{renderEmailField().map(item => item)}
		{showAddEmailButton &&
		  <IconButton
			onClick={() => addEmailAddressField()}
			className={clsx(classes.bottomSpacing, classes.iconButton)}
		  >
			{ADD_EMAIL}
			<PlusIcon />
		  </IconButton>
		}
		<InputField
		  label={COMPANY}
		  placeholder={CONTACTS_COMPANY_PLACEHOLDER}
		  wrapperClass={classes.bottomSpacing}
		  className={classes.inputField}
		  boldLabel
		  value={form.company}
		  error={formErrors.company?.hasError}
		  errorMessage={formErrors.company?.validatorResults.errorMessage}
		  name="company"
		  onChange={(e) => changeHandler("company", e.target.value)}
		/>
		<InputField
		  label={ROLE}
		  placeholder={CONTACTS_ROLE_PLACEHOLDER}
		  wrapperClass={classes.bottomSpacing}
		  className={classes.inputField}
		  boldLabel
		  value={form.role}
		  error={formErrors.role?.hasError}
		  errorMessage={formErrors.role?.validatorResults.errorMessage}
		  name="role"
		  onChange={(e) => changeHandler("role", e.target.value)}
		/>
		<InputField
		  label={URL}
		  placeholder={CONTACTS_URL_PLACEHOLDER}
		  wrapperClass={classes.bottomSpacing}
		  className={classes.inputField}
		  boldLabel
		  value={form.url}
		  name="url"
		  onChange={(e) => changeHandler("url", e.target.value)}
		/>
		<CustomLabel 
		  boldLabel
		  label="Notes"
		/>
		<TextareaAutosize 
		  value={form.notes}
		  rowsMin={2}
		  rowsMax={5}
		  onChange={(e) => changeHandler("notes", e.target.value)}
		  className={classes.textarea}
		  name="notes"
		/>
	  </DialogContent>
	  <DialogActions>
		<DialogButton
		  label={contactData?.name ? UPDATE_CONTACT : SAVE_CONTACT}
		  fullWidth
		  onClick={submitHandler}
		  isLoading={
			isContactCreationPending ||
			isContactUpdatePending || 
			isPhoneNumberAttachmentPending || 
			isPhoneNumberUpdatePending || 
			requestPending ||
			!open
		  } 
		/>
	  </DialogActions>
	</Dialog>
  );
};

const useStyles = makeStyles({
  iconButton: {
    fontSize: '14px',
    border: '1px solid var(--color-dark-grey)',
    borderRadius: '4px',
    width: '130px',
    padding: '6px 4px 6px 10px',
    height: '29px',
    justifyContent: 'space-between'
  },
  textarea: {
    border: '1px solid var(--color-dark-grey)',
    borderRadius: '4px',
    padding: '6px 16px',
    boxSizing: 'border-box',
    minHeight: '30px',
    width: '100%',
    resize: 'vertical',

    "&:focus" : {
      outline: "none !important",
      border: '1px solid var(--color-pumpkin-orange)',
    }
  },
  inputField: {
    height: '30px',

    '& .MuiOutlinedInput-input': {
      padding: '6px 16px'
    }
  },
  bottomSpacing: {
    marginBottom: '10px',
  },
});

export default memo(CreateContactDialog);