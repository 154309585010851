import React from 'react';
import * as Library from '@material-ui/core'
import { DialogActionsProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const DialogActions: React.FC<DialogActionsProps> = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Library.DialogActions 
      {...props}
      className={clsx(classes.root, className)}
    >
      {children}
    </Library.DialogActions>
  );
};

const useStyles = makeStyles({
  root: {
    padding: "2px 25px",
    marginBottom: "17px",
  },
});

export default DialogActions;