import Loader from '@components/PresentationComponents/Loader';
import { CustomTablePagination } from '@components/PresentationComponents/TableComponents/CustomTablePagination';
import PlainTable from '@components/PresentationComponents/TableComponents/PlainTable';
import { UnderlinedTitle } from '@components/PresentationComponents/UnderlinedTitle';
import { useAppSelector } from '@helpers/hooks/useAppSelector.hook';
import { IconButton, Theme, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { IPagination } from '@models/IPagination';
import { IBlockedNumber } from '@models/IPhoneNumbers';
import React, { useEffect, useState } from 'react';
import CallBlockedNumbersService from '@services/BlockedNumberService.service';
import { formatPhoneNumber } from '@helpers/functions/phoneNumberFormatter';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import BlockNumberDialog from './BlockNumberDialog';
import UnblockNumberDialog from './UnblockNumberDialog';
import { BLOCKED_NUMBERS, BLOCK_ANONYMOUS_CALLERS, IS_ANONYMOUS_CALLER } from './constants';
import InputButton from '@components/PresentationComponents/FormComponents/InputButton';
import { ComponentDimensions } from '@constants/appConstants';
import useService, { ErrorStatusCode } from '@helpers/hooks/useService.hook';
import ToggledRadioButton from '@components/PresentationComponents/FormComponents/ToggledRadioButton';
import UserService from '@services/User.service';
import { FeatureIdsEnum, IAccount } from "@models/Account.models";
import { useToasts } from 'react-toast-notifications';
import { ApiErrorMessages } from '@constants/uiString';
import uiString from "@constants/uiString";
import useAccessBlocker from '@helpers/hooks/useAccessBlocker.hook';
import PlanService from '@services/Plan.service';
import { fetchBlockedNumbers } from '@actions/callActions';
import { useDispatch } from 'react-redux';

const BlockedNumbersStrings = uiString.SETTINGS_AND_PREF.BLOCKED_PHONE_NUMBERS;

type BlockedNumbersProps = {
	fullSizePreferences: boolean;
};

const BlockedPhoneNumbers: React.FC<BlockedNumbersProps> = ({ fullSizePreferences }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [blockedNumbers, setBlockedNumbers] = useState<IBlockedNumber[]>([]);
  const [pageState, setPageState] = useState<IPagination | null>(null);
  const rowsPerPage = useAppSelector(
    (state) => state.preferences.tableSize.blockedNumbers
  );
  const [componentIsLoaded, setComponentIsLoaded] = useState(true);
  const { UnBlockNumber, GetByPage } = useService(CallBlockedNumbersService);
  const { PatchAccountDetails, GetAccountDetails } = useService(UserService);
  const accountId = useAppSelector((state) => state.authentication.accountId);
  const [openBlockingDialog, setOpenBlockingDialog] = useState(false);
  const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
  const [unblockNumber, setUnblockNumber] = useState('');
  const handlePageChange = (page: number) => BlockedNumbersGet(page);
  const [disableCallBlock, setDisableCallBlock] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<IAccount | null>(null);
  const { addToast } = useToasts();
  const mapForwardedNumberToTableData = (data: IBlockedNumber) => ({
    ...data,
    enabled: data.enabled,
    [BlockedNumbersStrings.TABLE_COL_LABELS[0]]:
      data.number && formatPhoneNumber(data.number),
  });
  const { GuardDialog, permissionGuard } = useAccessBlocker(
    FeatureIdsEnum.INBOUND_CALLER_ID_ROUTING,
	false,
	fullSizePreferences,
  );
  const dispatch = useDispatch();
  const BlockedNumbersGet = async (page?: number, isInit: boolean = false) => {
    !isInit && setIsLoading(true);
    try {
      const { data, pagination } = await GetByPage(
        page ?? pageState?.currentPage ?? 1,
        rowsPerPage,
        accountId ? accountId : 0
      );
      if (componentIsLoaded) {
        setBlockedNumbers(data);
        setPageState(pagination);
        !isInit && setIsLoading(false);
      }
    } catch (error) {
      !isInit && setIsLoading(false);
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  /**
   * Add new number to the existing blocked numbers array
   */
  const blockNumberHandler = (input: IBlockedNumber) => {
    // setBlockedNumbers((prevState) => ([ input, ...prevState ]));
    BlockedNumbersGet();
    dispatch(fetchBlockedNumbers());
  };

  /**
   * Open unblock number dialog and set state for phone number
   */
  const handleOpenUnblock = (phoneNumber: string) => {
    setOpenUnblockDialog(true);
    setUnblockNumber(phoneNumber);
  };

  const toggleBlockHandler = permissionGuard(async (value: boolean) => {
    try {
      if (!disableCallBlock) {
        setDisableCallBlock(true);
        const newUserDetails: IAccount = {
          ...(userDetails as IAccount),
          blockAnonymousCallers: value,
        };
        await PatchAccountDetails(String(accountId), {
          blockAnonymousCallers: value,
        });
        setUserDetails(newUserDetails);
        setDisableCallBlock(false);
      }
    } catch (error) {
      setDisableCallBlock(false);
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  });

  const init = async () => {
    try {
      setIsLoading(true);
      await BlockedNumbersGet(1, true);
      let details = await GetAccountDetails(String(accountId));
      setUserDetails(details);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  useEffect(() => {
    init();
    return () => setComponentIsLoaded(false);
  }, []);

  useEffect(() => {
    if (blockedNumbers.length) {
      BlockedNumbersGet();
    }
  }, [rowsPerPage]);

  return (
    <>
      <Loader open={isLoading} />
      <UnderlinedTitle>{BLOCKED_NUMBERS}</UnderlinedTitle>
      {<Grid direction="column" className={classes.content}>
        <Grid className={classes.controls}>
          <div className={classes.toggleWrapper}>
            {userDetails && (
              <ToggledRadioButton 
                checked={Boolean(userDetails.blockAnonymousCallers)}
                onChange={(e) => toggleBlockHandler(e.target.checked)}
                label={BLOCK_ANONYMOUS_CALLERS}
                name={IS_ANONYMOUS_CALLER}
              />
            )}
          </div>
          <div className={classes.buttonWrapper}>
            <InputButton 
              plusIcon
              className={classes.addBtn}
              color="primary"
              onClick={() => setOpenBlockingDialog(true)}
              minWidth={isMobile ? "auto" : ComponentDimensions.PREFERENCES_ADD_BUTTON_WIDTH + 10}
            >
              {BlockedNumbersStrings.BLOCK_NUMBER}
            </InputButton>
          </div>
        </Grid>
        <Grid>
          <CustomTablePagination
            pagination={pageState}
            handlePageChange={handlePageChange}
            storeKey="blockedNumbers"
          >
            <PlainTable
              data={blockedNumbers}
              dataMapper={mapForwardedNumberToTableData}
              selectColumns={[BlockedNumbersStrings.TABLE_COL_LABELS[0]]}
              fullWidth
              columnSize={isMobile ? undefined : ["auto", "80px"]}
              actions={[
                {
                  colLabel: BlockedNumbersStrings.TABLE_COL_LABELS[1],
                  width: isMobile ? undefined : "80px",
                  ActionComponent: (row) => (
                    <IconButton onClick={() => handleOpenUnblock(row.number)}>
                      <RemoveCircle style={{ color: 'var(--color-button-red)' }} />
                    </IconButton>
                  )
                }
              ]}
            />
          </CustomTablePagination>
        </Grid>
        <BlockNumberDialog 
          open={openBlockingDialog}
          onClose={() => setOpenBlockingDialog(false)}
          blockNumberHandler={blockNumberHandler} 
        />
        <UnblockNumberDialog 
          open={openUnblockDialog}
          phonenumber={unblockNumber}
          onClose={() => setOpenUnblockDialog(false)}
          afterUnblock={BlockedNumbersGet}
        />
      </Grid>}
      <GuardDialog />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
	  content: {
		width: "100%",
		padding: 0,
	  },
	  controls: {
		marginBottom: theme.spacing(2),
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		[theme.breakpoints.down('sm')]: {
		  flexDirection: "column",
		  alignItems: "stretch",
		},
	  },
	  toggleWrapper: {
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
		  marginBottom: 0,
		},
	  },
	  buttonWrapper: {
		[theme.breakpoints.down('sm')]: {
		  width: "100%",
		},
	  },
	  addBtn: {
		fontSize: ComponentDimensions.PREFERENCES_INPUT_FONT_SIZE,
		height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
		[theme.breakpoints.down('sm')]: {
		  width: "100%",
		  marginBottom: "20px",
		},
	  },
	})
);

export default BlockedPhoneNumbers;
