import { URI, Grammar } from "sip.js";
export {default as isAxiosError} from "./error";

export const normalizeCallTarget = (
  target: string | URI,
  domain?: string
): URI | undefined => {
  const escapeUser = (user: string): string => {
    // Don't hex-escape ':' (%3A), '+' (%2B), '?' (%3F"), '/' (%2F).
    return encodeURIComponent(decodeURIComponent(user))
      .replace(/%3A/gi, ":")
      .replace(/%2B/gi, "+")
      .replace(/%3F/gi, "?")
      .replace(/%2F/gi, "/");
  };
  // If no target is given then raise an error.
  if (!target) {
    return;
    // If a SIP.URI instance is given then return it.
  } else if (target instanceof URI) {
    return target as URI;

    // If a string is given split it by '@':
    // - Last fragment is the desired domain.
    // - Otherwise append the given domain argument.
  } else if (typeof target === "string") {
    const targetArray: Array<string> = target.split("@");
    let targetUser: string;
    let targetDomain: string;

    switch (targetArray.length) {
      case 1:
        if (!domain) {
          return;
        }
        targetUser = target;
        targetDomain = domain;
        break;
      case 2:
        targetUser = targetArray[0];
        targetDomain = targetArray[1];
        break;
      default:
        targetUser = targetArray.slice(0, targetArray.length - 1).join("@");
        targetDomain = targetArray[targetArray.length - 1];
    }

    // Remove the URI scheme (if present).
    targetUser = targetUser.replace(/^(sips?|tel):/i, "");

    // Remove 'tel' visual separators if the user portion just contains 'tel' number symbols.
    if (/^[\-\.\(\)]*\+?[0-9\-\.\(\)]+$/.test(targetUser)) {
      targetUser = targetUser.replace(/[\-\.\(\)]/g, "");
    }

    // Build the complete SIP URI.
    target = "sip" + ":" + escapeUser(targetUser) + "@" + targetDomain;
    // Finally parse the resulting URI.

    return Grammar.URIParse(target);
  } else {
    return;
  }
};

export const getExtensionFromSignature = (signature: Uint8Array) => {
  let header = "";
  for (var i = 0; i < signature.length; i++) {
    header += signature[i].toString(16);
  }

  let type: string;
  switch (header) {
    case "89504e47":
      type = "png";
      break;
    case "47494638":
      type = "gif";
      break;
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      type = "jpeg";
      break;
    default:
      type = "unknown";
      break;
  }
  return type;
};

// Device detection utility
export const isMobileDevice = () => {
	if (!navigator || !navigator.userAgent) {
	  return false;
	}
  
	const userAgent = navigator.userAgent.toLowerCase();
	const mobilePatterns = [
	  /android/i,
	  /webos/i,
	  /iphone/i,
	  /ipad/i,
	  /ipod/i,
	  /blackberry/i,
	  /windows phone/i,
	  /opera mini/i,
	  /mobile/i,
	  /tablet/i
	];
  
	const isMobile = mobilePatterns.some(pattern => pattern.test(userAgent));
  
	const hasTouchScreen = (
	  'ontouchstart' in window ||
	  navigator.maxTouchPoints > 0 ||
	  // @ts-ignore
	  navigator.msMaxTouchPoints > 0
	);

	const isTablet = /ipad|tablet/i.test(userAgent);

	// this exclude tablets
	return isMobile && hasTouchScreen && !isTablet;
  };