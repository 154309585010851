import DropdownComponent from "@components/PresentationComponents/DropdownComponent";
import InputField from "@components/PresentationComponents/FormComponents/InputField";
import { Grid, makeStyles, OutlinedInputProps, Theme } from "@material-ui/core";
import { IContactPhoneNumberBody } from "@models/Contacts.models";
import React, { ChangeEventHandler, FC } from "react";
import { phoneTypeOptions } from "./constants";

type IProps = {
  value: IContactPhoneNumberBody;
  inputChangeHandler?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  dropdownChangeHandler: (event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
  }>) => void;
  label: string;
  name: string;
  errorMessage: string;
} & Omit<OutlinedInputProps, "">

const ContactsPhoneTypeRow: FC<IProps> = (
  { value, onChange, label, inputChangeHandler, dropdownChangeHandler, name, errorMessage, ...props }
) => {
  const classes = useStyles();

  return (
    <Grid 
      className={classes.phoneNumberWrapper}
    >
      <InputField
        {...props}
        label={label}
        className={classes.inputField}
        boldLabel
        wrapperClass={classes.wrapper}
        errorMessage={errorMessage}
        inputProps={{ maxLength: 17 }}
        value={value.phoneNumber}
        name={name}
        autoComplete="off"
        onChange={inputChangeHandler}
      />
      <DropdownComponent
        options={phoneTypeOptions}
        name={name}
        outlined
        fullWidth
        smallIcon
        className={classes.dropdown}
        value={value.type}
        onChange={dropdownChangeHandler}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  phoneNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    maxWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  inputField: {
    height: '30px',
    '& .MuiOutlinedInput-input': {
      padding: '6px 16px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdown: {
    marginTop: '26px',
    minWidth: '110px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      minWidth: '100%',
    },
  },
}));

export default ContactsPhoneTypeRow;