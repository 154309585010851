import Dialog from "@components/PresentationComponents/Dialog/Dialog";
import DialogActions from "@components/PresentationComponents/Dialog/DialogActions";
import DialogButton from "@components/PresentationComponents/Dialog/DialogButton";
import DialogContent from "@components/PresentationComponents/Dialog/DialogContent";

import useForm from "@helpers/hooks/useForm.hook";
import { Avatar, DialogProps, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { IContactFormData, IContact, IContactPhoneNumber } from "@models/Contacts.models";
import React, { FC, memo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    contactFormInitialValues,
    contactInitialValues,
    phoneTypeOptions,
    SAVE_CONTACT,
    UPDATE_CONTACT,
} from "./constants";

import CustomLabel from "@components/PresentationComponents/FormComponents/CustomLabel";
import InputButton from "@components/PresentationComponents/FormComponents/InputButton";
import CopyIcon from "@resources/icons/copy-icon.svg";
import CallIcon from "@resources/icons/view-contact-call-icon.svg";
import TextIcon from "@resources/icons/view-contact-text-icon.svg";
import { useToasts } from "react-toast-notifications";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import { createNewConversation, updateNewConversationPhoneNumber } from "@actions/conversationActions";
import routes from "@constants/routes";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { setOpenPreferences, togglePreferencesWindow, TOGGLE_VISIBILITY } from "@actions/preferencesActions";
import { openDialog } from "@actions/appActions";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import { trimPhoneNumber } from "@helpers/functions/phoneNumberFormatter";
import { setNumberToCall, setOutgoingPhoneNumber } from "@actions/callActions";
import ConfirmDialog from "@components/PresentationComponents/ConfirmDialog";
import uiString from "@constants/uiString";
import { useHistory } from "react-router-dom";
import { formatBothNationalInternationalSpecial } from "@helpers/functions/phoneNumberFormatter";

type IProps = {
    open: boolean;
    onClose: () => void;
    contactData: IContact | null;
} & Omit<DialogProps, "">



const ViewContactDialog: FC<IProps> = (
    { onClose, contactData, open, ...props }
) => {
    const {
        resetToDefault,
    } = useForm<IContactFormData>(contactFormInitialValues);

    const { addToast } = useToasts();
    const classes = useStyles();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const history = useHistory();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmTextDialogOpen, setConfirmTextDialogOpen] = useState(false);
    const [phoneNumberToCall, setPhoneNumberToCall] = useState('');
    const [numberToText, setNumberToText] = useState('');
    const outgoingPhoneNumbers = useAppSelector(state => state.preferences.outgoingPhoneNumbers);
    const outgoingTextNumbers = useAppSelector(state => state.preferences.outgoingTextPhoneNumbers);
    const selectedOutGoingTextNumber = useAppSelector(state => state.preferences.selectedOutgoingTextNumber);

    const dispatch = useDispatch();

    const handleCopy = (text: string) => {
        addToast("Copied to clipboard!", { appearance: 'success', autoDismiss: true });
        navigator.clipboard.writeText(text);
    }

    const handleTextContact = (phoneNumber: string) => {
         // we can only call / text US phone numbers
         const tempNumber = parsePhoneNumber(phoneNumber);
         if (tempNumber.country === "US") {
            const enabled = outgoingTextNumbers.some(
                (element) => trimPhoneNumber(element.getPhoneNumber()) === trimPhoneNumber(phoneNumber)
            );
        
            if (enabled === false) {
                dispatch(createNewConversation(selectedOutGoingTextNumber as string));
                dispatch(updateNewConversationPhoneNumber(trimPhoneNumber(phoneNumber)));
                handleClose();
                dispatch(setOpenPreferences(false));
                history.push(`${routes.TEXT_MESSAGES}`);
            }
            else if (enabled === true) {
                addToast('Not allowed', { appearance: 'error', autoDismiss: true });
            }
        }
        else {
            addToast("Only US numbers allowed.", { appearance: 'error', autoDismiss: true });
        }
    }

    const handleCallContact = (phoneNumber: string) => {
        // we can only call / text US phone numbers
        const tempNumber = parsePhoneNumber(phoneNumber);
        if (tempNumber.country === "US") {
            const enabled = outgoingPhoneNumbers.some(
                (element) => trimPhoneNumber(element.getPhoneNumber()) === trimPhoneNumber(phoneNumber)
            );
            if (enabled === false) {
                dispatch(setNumberToCall(trimPhoneNumber(phoneNumber)));
                handleClose();
                dispatch(setOpenPreferences(false));
                dispatch(setNumberToCall(trimPhoneNumber(phoneNumber)));
                history.push(`${routes.KEYPAD}`);
            }
            else if (enabled === true) {
                addToast('Unable to call this number.', { appearance: 'error', autoDismiss: true });
            }
        }
        else {
            addToast("Only US numbers allowed.", { appearance: 'error', autoDismiss: true });
        }
    }

    const handleClose = () => {
        onClose();
    };

    const showConfirmationDialog = (phoneNumber: string) => {
        setConfirmDialogOpen(true);
        setPhoneNumberToCall(phoneNumber);
    }

    const hideConfirmationDialog = () => {
        setConfirmDialogOpen(false);
    }

    const showConfirmTextDialog = (phoneNumber: string) => {
        setConfirmTextDialogOpen(true);
        setNumberToText(phoneNumber);
    }

    const hideConfirmTextDialog = () => {
        setConfirmTextDialogOpen(false);
    }

    return (
        <Dialog
            {...props}
            open={open}
            onClose={() => handleClose()}
            title={"Contact Details"}
            maxWidth="sm"
            fullWidth
        >
            <DialogContent className={classes.dialogContent}>
                <CustomLabel
                    boldLabel
                    label="Contact Name"
                    className={classes.contactNameLabel}
                />
                <Grid container spacing={1} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        {contactData &&
                            <div className={classes.phoneNumberIconContainer}>
                                <div className={classes.contactName}>{contactData.name}</div>
                                <IconButton onClick={() => handleCopy(contactData.name)}><CopyIcon /></IconButton>
                            </div>
                        }
                    </Grid>
                </Grid>
                <Grid container className={classes.gridContainer}>
                    {contactData && contactData.phoneNumbers.length > 0 &&
                        <Grid item xs={12} className={classes.sectionContainer}>
                            <CustomLabel
                                boldLabel
                                label="Phone numbers"
                                className={classes.defaultLabel}
                            />
                            {contactData.phoneNumbers.map(function (a) {
                                return (
                                    <Grid key={a.id} container className={classes.itemContainer}>
                                        <Grid item xs={12}>
                                            <div className={classes.phoneNumberIconContainer}>
                                                {formatBothNationalInternationalSpecial(a.phoneNumber)}
                                                <IconButton onClick={() => handleCopy(a.phoneNumber)}><CopyIcon /></IconButton>
                                                <IconButton onClick={() => showConfirmationDialog(a.phoneNumber)}><CallIcon /></IconButton>
                                                <IconButton onClick={() => showConfirmTextDialog(a.phoneNumber)}><TextIcon /></IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    {contactData && contactData.emails.length > 0 &&
                        <Grid item xs={12} className={classes.sectionContainer}>
                            <CustomLabel
                                boldLabel
                                label="Emails"
                                className={classes.defaultLabel}
                            />
                            {contactData.emails.map(function (a, index) {
                                return (
                                    <Grid key={a+index} container className={classes.itemContainer}>
                                        <Grid item xs={12}>
                                            <div className={classes.phoneNumberIconContainer}>
                                                {a}
                                                <IconButton onClick={() => handleCopy(a)}><CopyIcon /></IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                </Grid>
                <Grid container className={classes.gridContainer}>
                    {contactData?.role &&
                        <Grid item xs={12} className={classes.sectionContainer}>
                            <CustomLabel
                                boldLabel
                                label="Role"
                                className={classes.defaultLabel}
                            />
                            <Grid container className={classes.itemContainer}>
                                <Grid item xs={12}>
                                    <div className={classes.phoneNumberIconContainer}>
                                        {contactData.role}
                                        <IconButton onClick={() => handleCopy(contactData.role)}><CopyIcon /></IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {contactData?.company &&
                        <Grid item xs={12} className={classes.sectionContainer}>
                            <CustomLabel
                                boldLabel
                                label="Company"
                                className={classes.defaultLabel}
                            />
                            <Grid container className={classes.itemContainer}>
                                <Grid item xs={12}>
                                    <div className={classes.phoneNumberIconContainer}>
                                        {contactData.company}
                                        <IconButton onClick={() => handleCopy(contactData.company)}><CopyIcon /></IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {contactData?.notes &&
                    <Grid container className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <CustomLabel
                                boldLabel
                                label="Notes"
                                className={classes.defaultLabel}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.phoneNumberIconContainer}>
                                {contactData.notes}
                                <IconButton onClick={() => handleCopy(contactData.notes)}><CopyIcon /></IconButton>
                            </div>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <ConfirmDialog
                open={confirmDialogOpen}
                confirmButtonLabel={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_CONFIRM_BUTTON}
                header={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_HEADER}
                content={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_BODY}
                onCancel={hideConfirmationDialog}
                onConfirm={() => handleCallContact(phoneNumberToCall)}
            />
             <ConfirmDialog
                open={confirmTextDialogOpen}
                confirmButtonLabel={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_CONFIRM_BUTTON}
                header={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_HEADER}
                content={uiString.CONTACTS.VIEW_CONTACTS.DIALOG_TEXT_CONTACT_BODY}
                onCancel={hideConfirmTextDialog}
                onConfirm={() => handleTextContact(numberToText)}
            />
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
        padding: '24px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px',
        },
    },
    gridContainer: {
        marginBottom: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
        },
    },
    sectionContainer: {
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.5rem',
        },
    },
    itemContainer: {
        marginBottom: '0.5rem',
    },
    contactName: {
        fontSize: '28px',
        fontWeight: 'bold',
        maxWidth: '300px',
        width: '100%',
        lineHeight: "1",
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            maxWidth: '100%',
        },
    },
    phoneNumberIconContainer: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
        overflowWrap: "anywhere",
        "& > .MuiIconButton-root": {
            visibility: 'hidden',
            padding: '8px'
        },
        "&:hover > .MuiIconButton-root": {
            visibility: 'visible'
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            "& > .MuiIconButton-root": {
                visibility: 'visible',
            },
        },
    },
    defaultLabel: {
        fontSize: '16px',
        marginBottom: '0.5rem',
    },
    iconButton: {
        fontSize: '14px',
        border: '1px solid var(--color-dark-grey)',
        borderRadius: '4px',
        width: '130px',
        padding: '6px 4px 6px 10px',
        height: '29px',
        justifyContent: 'space-between'
    },
    copyButton: {
        '& > .MuiButton-label > .MuiButton-endIcon': {
            marginLeft: '0',
            marginRight: '0'
        }
    },
    heading: {
        maxWidth: '150px',
        width: '100%',
        wordBreak: 'break-all'
    },
    contactNameLabel: {
        marginBottom: '12px !important'
    },
    copyItemButton: {
        color: '#000',
        fontSize: '14px',
        height: 'auto',
        textAlign: 'left',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
    },
}));

export default memo(ViewContactDialog);
