import Tooltip from "@components/PresentationComponents/FormComponents/Tooltip";
import { ComponentDimensions } from "@constants/appConstants";
import DateFnsUtils from "@date-io/date-fns";
import { formatSecondsWithQualifier } from "@helpers/functions/converters";
import createKeyIndex from "@helpers/functions/createKeyIndex";
import useAccessBlocker from "@helpers/hooks/useAccessBlocker.hook";
import { useAppSelector } from "@helpers/hooks/useAppSelector.hook";
import useService, { ErrorStatusCode } from "@helpers/hooks/useService.hook";
import { Divider, FormGroup, Grid, IconButton } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDropdownIcon from "@material-ui/icons/ArrowDropDown";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FeatureIdsEnum } from "@models/Account.models";
import { CallType } from "@models/Callhistory.models";
import { IForwardedPhoneNumber } from "@models/IPhoneNumbers";
import DownloadIcon from "@resources/icons/download.svg";
import { CallHistoryData } from "@services/dataClasses";
import { formatPhoneNumber } from "@services/dataClasses/CallData";
import ForwardingPhoneNumbersService from "@services/ForwardPhoneNumbers.service";
import useAxios from "axios-hooks";
import clsx from "clsx";
import React, { memo, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useToasts } from "react-toast-notifications";
import uiString, { ApiErrorMessages } from "../../constants/uiString";
import CustomLabel from "../PresentationComponents/FormComponents/CustomLabel";
import InputButton from "../PresentationComponents/FormComponents/InputButton";
import InputField from "../PresentationComponents/FormComponents/InputField";
import {
  CallHistoryFilterQuery,
  DynamicSelectOptions,
  FilterOption,
  filterTypesEnum,
  FILTER_OPTIONS,
} from "./CallHistoryTypes";
import { CALL_HISTORY_TABLE_PAGE_NUMBER } from "./constants";

interface CallHistoryFiltersProps {
  filterQuery: CallHistoryFilterQuery;
  isAdvancedMode: boolean;
  setFilterQuery: (query: CallHistoryFilterQuery) => void;
  setInitialFilterOptions: () => Promise<any>;
  setAdvancedMode: (mode: boolean) => void;
  updateCallHistory: (fq?: CallHistoryFilterQuery) => Promise<any>;
  data: CallHistoryData[];
  onApplyFilters: (fq?: CallHistoryFilterQuery) => any;
}

const callHistoryStrings = uiString.SETTINGS_AND_PREF.CALL_HISTORY;

function CallHistoryFilters({
  filterQuery,
  isAdvancedMode,
  setFilterQuery,
  setInitialFilterOptions,
  setAdvancedMode,
  updateCallHistory,
  data,
  onApplyFilters,
}: CallHistoryFiltersProps) {
  const classes = useStyles();
  const [dynamicOptions, setDynamicOptions] = useState<DynamicSelectOptions>(
    {}
  );
  const [forwardingNumbersData, setForwardingNumbersData] = useState<
    IForwardedPhoneNumber[]
  >([]);
  const { GetByPage } = useService(ForwardingPhoneNumbersService);
  const [{ data: extensionsData }] = useAxios("/v2/extensions", {
    useCache: false,
  });
  const [
    { data: virtualNumbersData },
  ] = useAxios("/v2/virtual-numbers?forFiltering=1", { useCache: false }); //?forFiltering=1
  const [
    { data: voiceMailboxesData },
  ] = useAxios("/v2/voice-mailboxes?forFiltering=1", { useCache: false }); //?forFiltering=1
  const [{ data: menusData }] = useAxios("/v2/menus", { useCache: false });
  const [openPaywallDialog, setOpenPaywallDialog] = useState(false);
  const [error, setError] = useState(false);
  const [displayResetButton, setDisplayResetButton] = useState(false);
  const [filtersHaveChanged, setFiltersHaveChanged] = useState(false);
  const permittedFeatures = useAppSelector(
    (state) => state.authentication.permittedFeatures
  );
  const { addToast } = useToasts();
  const { GuardDialog, permissionGuard, ComponentGuard } = useAccessBlocker(
    FeatureIdsEnum.REPORTING,
	true,
	false
  );

  const callHistoryData = useMemo(
    () =>
      data.map((row) => ({
        date: row.getSimpleFormattedDateAndFullTime(),
        talkroute_number: row.getVirtualPhoneNumber(),
        call_to_number:
          row.type === CallType.OUTBOUND ? row.calledNumber : null,
        call_from_cnam:
          row.type === CallType.INBOUND && row.callerCName
            ? row.callerCName
            : null,
        call_from_number:
          row.type === CallType.INBOUND ? row.callerNumber : null,
        duration: formatSecondsWithQualifier(row.duration.toString()),
        direction: row.type,
        result: row.getCallResultFormatted()?.toLowerCase(),
      })) || [],
    [data]
  );

  useEffect(() => {
    getForwardingNumbers();
  }, []);

  useMemo(() => {
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      extensions: extensionsData?.data?.map((e) => ({
        id: e.id,
        value: e.number,
      })),
    }));
  }, [extensionsData]);

  useMemo(() => {
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      virtualNumbers: virtualNumbersData?.data?.map((v) => ({
        id: v.phoneNumber.replace("+", ""),
        value: v.phoneNumber
          ? formatPhoneNumber(v.phoneNumber)
          : formatPhoneNumber(v.phoneNumber),
      })),
    }));
  }, [virtualNumbersData]);

  useMemo(() => {
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      voiceMailboxes: voiceMailboxesData?.data?.map((v) => ({
        id: v.id,
        value: v.description,
      })),
    }));
  }, [voiceMailboxesData]);

  useMemo(() => {
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      menus: menusData?.data?.map((m, index) => ({
        id: m.id,
        value: m.name ? index + 1 + " " + m.name : index + 1,
      })),
    }));
  }, [menusData]);

  useMemo(() => {
    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      forwardingNumbers: forwardingNumbersData.map((f) => ({
        id: f.id,
        value: f.transformedDescription,
      })),
    }));
  }, [forwardingNumbersData]);

  const getForwardingNumbers = async () => {
    const { data } = await GetByPage(1, 200);
    data.sort((a, b) =>
      a.transformedDescription > b.transformedDescription ? -1 : 1
    );
    setForwardingNumbersData(data);
  };

  // handle change on any form field
  const handleChange = (property: string, value: any) => {
    setFiltersHaveChanged(true);
    setFilterQuery({
      ...filterQuery,
      [property]: value,
    });
  };

  // handle change on select fields
  const handleSelectEvent = (event: any) => {
    const value = event.target.value.id
      ? event.target.value.id
      : event.target.value;
    handleChange(event.target.name, value);
  };

  // handle change on input fields
  const handleInputEvent = (event: any) => {
    handleChange(event.target.name, event.target.value);
  };

  const setNewFilters = async () => {
    let _filterQuery = { ...filterQuery };
    if (filterQuery.duration && !filterQuery.durationOperator) {
      _filterQuery.durationOperator = "longer";
    }
    if (filterQuery.duration && !filterQuery.durationUnit) {
      _filterQuery.durationUnit = "seconds";
    }
    setFilterQuery({
      ..._filterQuery,
      page: CALL_HISTORY_TABLE_PAGE_NUMBER,
    });
    try {
      onApplyFilters(_filterQuery);
      await updateCallHistory({
        ..._filterQuery,
        page: CALL_HISTORY_TABLE_PAGE_NUMBER,
      });
      setFiltersHaveChanged(false);
      setDisplayResetButton(true);
    } catch (error) {
      if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.PAYMENT_REQUIRED
      ) {
        setFiltersHaveChanged(false);
        setOpenPaywallDialog(true);
      } else if (
        (error as any).response &&
        (error as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw error;
      }
    }
  };

  const resetHandler = async () => {
    try {
      await setInitialFilterOptions();
      setDisplayResetButton(false);
      setFiltersHaveChanged(false);
    } catch (err) {
      if (
        (err as any).response &&
        (err as any).response.status === ErrorStatusCode.FORBIDDEN
      ) {
        addToast(ApiErrorMessages.FORBIDDEN, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(ApiErrorMessages.UNEXPECTED, {
          appearance: "error",
          autoDismiss: true,
        });
        throw err;
      }
    }
  };

  const toggleAdvancedFilters = permissionGuard(() =>
    setAdvancedMode(!isAdvancedMode)
  );

  // render dropdown component
  const renderDropdownField = (option: FilterOption) => {
    const valueString = filterQuery[option.name]
      ? filterQuery[option.name]
      : option.values && option.values[0].id;
    return (
      <>
        {option.title && (
          <CustomLabel className={classes.inputLabel} label={option.title} />
        )}
        <Select
          name={option.name}
          value={valueString.toString()}
          onChange={handleSelectEvent}
          IconComponent={undefined}
          variant="outlined"
          className={clsx(
            classes.select,
            option.name === "forwardingNumber"
              ? classes.makeWidthBigger
              : undefined
          )}
          style={{ textAlign: option.title ? "left" : "center" }}
          MenuProps={
            {
              // disableScrollLock: true,
              //   anchorOrigin: {
              //   vertical: "bottom",
              //   horizontal: "left"
              // },
              // getContentAnchorEl: null,
              // transformOrigin: {
              //   vertical: "top",
              //   horizontal: "left"
              // },
            }
          }
        >
          {option.values?.map((props: any, valueIndex: number) => (
            <MenuItem key={valueIndex} value={props.id}>
              {props.value}
            </MenuItem>
          ))}
          {option.dynamicVariable &&
            dynamicOptions[option.dynamicVariable] &&
            dynamicOptions[option.dynamicVariable].map(
              (option: any, valueIndex: number) => (
                <MenuItem key={valueIndex} value={option.id}>
                  {option.value}
                </MenuItem>
              )
            )}
        </Select>
      </>
    );
  };

  // render input component
  const renderInputField = (option: FilterOption) => (
    <Grid>
      <InputField
        name={option.name}
        value={filterQuery[option.name]}
        onChange={handleInputEvent}
        type={option.inputType}
        label={option.title}
        className={classes.inputField}
      />
    </Grid>
  );

  // render date component
  const renderDateField = (option: FilterOption) => (
    <Grid>
      {option.title && (
        <CustomLabel label={option.title} className={classes.inputLabel} />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          value={filterQuery[option.name] ? filterQuery[option.name] : null}
          InputProps={{ disableUnderline: true }}
          className={classes.datePicker}
          onChange={(date) => handleChange(option.name, date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          onError={(err) => {
            if (err) {
              setError(true);
            } else {
              setError(false);
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );

  const renderDurationGroup = (option: FilterOption) => {
    const [
      durationOperator,
      duration,
      durationUnit,
    ] = option.values as FilterOption[];
    const operatorValueString = filterQuery[durationOperator.name]
      ? filterQuery[durationOperator.name]
      : durationOperator.values && durationOperator.values[0].id;
    const unitValueString = filterQuery[durationUnit.name]
      ? filterQuery[durationUnit.name]
      : durationUnit.values && durationUnit.values[0].id;

    return (
      <>
        <CustomLabel label={option.title} className={classes.inputLabel} />
        <div className={classes.buttonGroupWrapper}>
          <Select
            name={durationOperator.name}
            value={operatorValueString}
            onChange={handleSelectEvent}
            IconComponent={ArrowDropdownIcon}
            variant="outlined"
            className={`${classes.selectGroupItem} ${classes.groupLeftItem}`}
          >
            {durationOperator.values?.map((props: any, index: number) => (
              <MenuItem
                key={createKeyIndex(index, "duration-operator-item")}
                value={props.id}
              >
                {props.value}
              </MenuItem>
            ))}
          </Select>
          <InputField
            name={duration.name}
            value={filterQuery[duration.name] ?? ""}
            onChange={handleInputEvent}
            type={duration.inputType}
            className={clsx(classes.inputGroupItem)}
            wrapperClass={classes.inputGroupWrapper}
          />
          <Select
            name={durationUnit.name}
            value={unitValueString}
            onChange={handleSelectEvent}
            IconComponent={ArrowDropdownIcon}
            variant="outlined"
            className={`${classes.selectGroupItem} ${classes.groupRightItem}`}
          >
            {durationUnit.values?.map((props: any, index: number) => (
              <MenuItem
                key={createKeyIndex(index, "duration-unit-item")}
                value={props.id}
              >
                {props.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      </>
    );
  };

  return (
    <>
      <Grid className={classes.wrapper}>
        <div className={classes.buttonsWrapper}>
          {Object.values(FILTER_OPTIONS)
            .filter((option: FilterOption) => option.advanced === false)
            .map((option: FilterOption, index: number) => (
              <FormGroup
                key={createKeyIndex(index, "setting-filter-options")}
                className={classes.formControl}
              >
                {option.type === filterTypesEnum.DROPDOWN &&
                  renderDropdownField(option)}
                {option.type === filterTypesEnum.INPUT &&
                  renderInputField(option)}
                {option.type === filterTypesEnum.DATE &&
                  renderDateField(option)}
              </FormGroup>
            ))}
          <Divider
            style={{
              height: ComponentDimensions.TABLE_FILTER_DIVIDER_HEIGHT,
              width: "1px",
              backgroundColor: "#d8d8d8",
              marginRight: "11px",
            }}
          />
          <InputButton
            className={classes.filterControls}
            minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
            color="primary"
            onClick={toggleAdvancedFilters}
            label={callHistoryStrings.FILTERS.ADVANCED_FILTERS}
          />
          {filtersHaveChanged && (
            <InputButton
              className={classes.filterControls}
              minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
              onClick={setNewFilters}
              color="secondary"
              disabled={error}
              label={callHistoryStrings.FILTERS.APPLY_FILTERS}
            />
          )}
          {displayResetButton && (
            <InputButton
              className={classes.filterControls}
              minWidth={ComponentDimensions.TABLE_FILTER_BUTTON_MIN_WIDTH}
              onClick={resetHandler}
              color="inherit"
              label={callHistoryStrings.FILTERS.RESET}
            />
          )}
        </div>
        <Tooltip
          styling="standard"
          placement="top"
          title={uiString.SETTINGS_AND_PREF.CALL_HISTORY.TOOLTIPS.EXPORT_TO_CSV}
        >
          <ComponentGuard
            component={
              <CSVLink
                filename={`call-history-page_${filterQuery.page}.csv`}
                data={callHistoryData}
                target="_blank"
              >
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </CSVLink>
            }
            fallback={
              <IconButton onClick={permissionGuard(() => {})}>
                <DownloadIcon />
              </IconButton>
            }
          />
        </Tooltip>
      </Grid>
      {isAdvancedMode && (
        <Grid className={classes.advancedOptionsWrapper}>
          {Object.values(FILTER_OPTIONS)
            .filter((option: FilterOption) => option.advanced)
            .map((option: FilterOption, index: number) => (
              <FormGroup
                key={createKeyIndex(index, "call-history-advanced-filters")}
                className={classes.advancedFiltersFormControl}
              >
                {option.type === filterTypesEnum.DROPDOWN &&
                  renderDropdownField(option)}
                {option.type === filterTypesEnum.INPUT &&
                  renderInputField(option)}
                {option.type === filterTypesEnum.DATE &&
                  renderDateField(option)}
                {option.type === filterTypesEnum.GROUP &&
                  renderDurationGroup(option)}
              </FormGroup>
            ))}
        </Grid>
      )}
      <GuardDialog />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },

    formControl: {
      minWidth: 166,
      height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
    },
    advancedFiltersFormControl: {
      minWidth: 125,
      minHeight: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
    },
    makeWidthBigger: {
      width: 175,
    },
    select: {
      lineHeight: "normal",
      marginRight: "11px",
      marginBottom: "11px",
      height: "100%",
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--color-pumpkin-orange)",
          zIndex: 1,
        },
      },
      "& > .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "6px",
      },
      "& > div": {
        padding: "6px",
      },
      "& > svg": {
        display: "none",
      },
    },
    buttonGroupWrapper: {
      display: "flex",
      marginRight: "11px",
      marginBottom: "11px",
    },
    selectGroupItem: {
      lineHeight: "normal",
      height: "100%",
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--color-pumpkin-orange)",
          zIndex: 1,
        },
      },
      "& > .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "25px",
      },
      "& > div": {
        padding: "6px",
      },
      "& > svg": {
        top: "5px",
      },
    },
    groupLeftItem: {
      "& fieldset": {
        borderRadius: "4px 0 0 4px",
      },
    },
    groupRightItem: {
      "& fieldset": {
        borderRadius: "0 4px 4px 0",
      },
    },
    inputGroupWrapper: {
      width: "fit-content",
      marginLeft: -1,
      marginRight: -1,
    },
    hoveredInput: {
      "& fieldset": {
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
      },
    },
    inputGroupItem: {
      height: "29px",
      width: "80px",

      // "& .MuiOutlinedInput-root": {
      //   "& .Mui-focused > fieldset": {
      //     borderColor: "var(--color-pumpkin-orange)",
      //     zIndex: 1,
      //   },
      // },
      "& .MuiOutlinedInput-input": {
        padding: "0 6px 1px",
      },
      "& fieldset": {
        borderRadius: 0,
      },
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    printButton: {
      minWidth: "fit-content",
      padding: theme.spacing(0, 1),
    },
    inputLabel: {
      margin: 0,
      fontSize: ComponentDimensions.TABLE_FILTER_LABEL,
      lineHeight: "normal",
    },
    inputField: {
      height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
      marginRight: "11px",
      marginBottom: "11px",
    },
    advancedOptionsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "11px 0",
      alignItems: "flex-end",
    },
    filterControls: {
      height: ComponentDimensions.TABLE_FILTER_BUTTON_HEIGHT,
      fontSize: ComponentDimensions.TABLE_FILTER_LABEL,
      marginRight: "11px",
    },
    datePicker: {
      "& .MuiInput-root": {
        height: ComponentDimensions.TABLE_FILTER_TEXT_FIELD_HEIGHT,
        border: "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "4px",
        marginRight: "11px",
        marginBottom: "11px",

        "& input": {
          paddingLeft: "8px",
        },
      },
    },
  })
);

export default memo(CallHistoryFilters);
