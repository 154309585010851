import { setPermittedFeatures } from "@actions/userActions";
import { PaywallDialog } from "@components/PresentationComponents/PaywallDialog";
import uiString from "@constants/uiString";
import { isFeatureAllowed } from "@helpers/functions/verificationFns";
import { Link } from "@material-ui/core";
import { FeatureIdsEnum } from "@models/Account.models";
import UserService from "@services/User.service";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./useAppSelector.hook";

const PaywallMessages = {
  [FeatureIdsEnum.ACCOUNT_USER]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.DEFAULT_TEXT,
  },
  [FeatureIdsEnum.CALL_MENU]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.CALL_MENU_TEXT,
  },
  [FeatureIdsEnum.CALL_TRANSFER]: {
    title:
      uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG
        .HEADER_DISCOVERED_PREMIUM_FEATURE,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.TRANSFER_CODES_TEXT,
  },
  [FeatureIdsEnum.HOURS_OF_OPERATION]: {
    title:
      uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG
        .HEADER_DISCOVERED_PREMIUM_FEATURE,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HOURS_OF_OPERATIONS_TEXT,
  },
  [FeatureIdsEnum.INBOUND_CALLER_ID_ROUTING]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.CALLER_ID_ROUTING_TEXT,
  },
  [FeatureIdsEnum.REPORTING]: {
    title:
      uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG
        .HEADER_DISCOVERED_PREMIUM_FEATURE,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.REPORTING_TEXT,
  },
  [FeatureIdsEnum.SCHEDULED_FORWARDING]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.DEFAULT_TEXT,
  },
  [FeatureIdsEnum.TEXT_MESSAGES]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.DEFAULT_TEXT,
  },
  [FeatureIdsEnum.VMBOX]: {
    title: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.HEADER_MAILBOX_LIMIT,
    feature: uiString.SETTINGS_AND_PREF.PAYWALL_DIALOG.VMBOX_TEXT,
    voicemailText: {
      firstParagraph:
        "The mailbox that you are trying to add exceeds the number of mailboxes included with your current subscription plan. To add a new paid voicemail box, please follow our ",
      linkText: "Add New Voicemail Guide here.",
      link:
        "https://support.talkroute.com/hc/en-us/articles/214750758-How-to-Add-New-Voicemail-Boxes",
      lastParagraph:
        "Please visit our Billing page to compare plans and add-ons available for your account.",
    },
  },
};

type ProtectedFunction<T extends any[], R> = (...params: T) => R | void;
const useAccessBlocker = (
  feature: FeatureIdsEnum,
  dismissable: boolean = true,
  fullSizePreferences: boolean,
) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const permittedFeatures = useAppSelector(
    (state) => state.authentication.permittedFeatures
  );
  const accountId = useAppSelector((state) => state.authentication.accountId);

  const isAllowed = useMemo(
    () => isFeatureAllowed(feature, permittedFeatures),
    [feature, permittedFeatures]
  );

  const permissionGuard = <ParamType extends any[], ReturnType>(
    callBack?: (...params: ParamType) => ReturnType
  ): ProtectedFunction<ParamType, ReturnType> => (...params: ParamType) =>
    isAllowed ? callBack && callBack(...params) : setOpen(true);

  const triggerGuard = () => setOpen(true);

  const doublecheckPermissions = async (callback?: () => void) => {
    const { GetPermittedFeatures } = UserService();
    try {
      const { data } = await GetPermittedFeatures(String(accountId));
      const isAllowed = isFeatureAllowed(feature, data);
      if (!isAllowed && callback) {
        callback();
      }
      dispatch(setPermittedFeatures(data));
    } catch (error) {
      throw error;
    }
  };

  const GuardDialog = () => {
    const message = PaywallMessages[feature];
    const textToDisplay =
      feature === FeatureIdsEnum.VMBOX ? (
        <div>
          {PaywallMessages[feature].voicemailText.firstParagraph}
          <Link href={PaywallMessages[feature].voicemailText.link}>
            {PaywallMessages[feature].voicemailText.linkText}
          </Link>
          <br />
          <br />
          {PaywallMessages[feature].voicemailText.lastParagraph}
        </div>
      ) : (
        <>{message.feature}</>
      );

    return (
      <PaywallDialog
        text={textToDisplay}
        title={PaywallMessages[feature].title}
        open={open}
        dismissable={dismissable}
        fullSizePreferences={fullSizePreferences}
        onClose={() => setOpen(false)}
      />
    );
  };

  const ComponentGuard = ({
    component,
    fallback,
  }: {
    component: JSX.Element;
    fallback: JSX.Element;
  }) => (isAllowed ? component : fallback);

  return {
    GuardDialog,
    permissionGuard,
    ComponentGuard,
    triggerGuard,
    doublecheckPermissions,
    isAllowed,
  };
};

export default useAccessBlocker;
